// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-intro-index-tsx": () => import("./../../../src/pages/intro/index.tsx" /* webpackChunkName: "component---src-pages-intro-index-tsx" */),
  "component---src-pages-special-index-v-2-tsx": () => import("./../../../src/pages/special/index-v2.tsx" /* webpackChunkName: "component---src-pages-special-index-v-2-tsx" */),
  "component---src-templates-article-index-js": () => import("./../../../src/templates/Article/index.js" /* webpackChunkName: "component---src-templates-article-index-js" */),
  "component---src-templates-article-list-index-js": () => import("./../../../src/templates/articleList/index.js" /* webpackChunkName: "component---src-templates-article-list-index-js" */)
}

