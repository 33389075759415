"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.globalStyles = exports.theme = void 0;
var _globalStyles;
var theme = {
    palette: {
        primary: '#ffe400',
        secondary: '#272727',
        textPrimary: '#ddd',
        textSecondary: '#ffe400'
    },
    spacing: function spacing(size) {
        return size * 12;
    },
    fontSize: function fontSize(size) {
        return 1.8 * size + "rem";
    },
    breakpoints: {
        sm: '@media (max-width: 600px)'
    }
}; // global styles
exports.theme = theme;
var globalStyles = (_globalStyles = {
    '@global': {
        html: {
            fontSize: '62.5%'
        },
        body: {
            margin: 0,
            background: theme.palette.secondary,
            color: theme.palette.textPrimary,
            font: '300 1em/1.8 PingFang SC, Lantinghei SC, Microsoft Yahei, Hiragino Sans GB, Microsoft Sans Serif, WenQuanYi Micro Hei, sans-serif',
            fontSize: '1.8rem'
        },
        'a, a:hover, a:visited': {
            color: theme.palette.textPrimary
        }
    }
}, _globalStyles[theme.breakpoints.sm] = {
    '@global': {
        html: {
            fontSize: '50%'
        }
    }
}, _globalStyles);
exports.globalStyles = globalStyles;
exports.default = theme;
