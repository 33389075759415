// import { applyGlobalStyles } from "./src/theme";
const React = require("react")
const { createUseStyles } = require("react-jss")
const { globalStyles } = require('./src/theme')

const GlobalStyleWrapper = (props) => {
    // console.log(globalStyles)
    const useGlobalStyles = createUseStyles(globalStyles)
    useGlobalStyles()
    return props.children || null
}

exports.wrapPageElement = ({ element, props }) => {
    return <GlobalStyleWrapper {...props}>{element}</GlobalStyleWrapper>
}
